import React, { useRef, useState, useEffect } from 'react';
import './AnimeBlock.css';

const AnimeBlock = ({
    title,
    items,
    imageStyle,
    textWidth,
    gap,
    overlay,
    hoverEffect,
    onCardClick,
    titleStyle = { color: 'white' },
    infoStyle = { color: '#b3b3b3' }
}) => {
    const listRef = useRef(null);
    const [canScrollLeft, setCanScrollLeft] = useState(false);
    const [canScrollRight, setCanScrollRight] = useState(false);

    const updateScrollButtons = () => {
        const list = listRef.current;
        if (list) {
            setCanScrollLeft(list.scrollLeft > 0);
            setCanScrollRight(list.scrollLeft + list.clientWidth < list.scrollWidth);
        }
    };

    const scrollLeft = () => {
        listRef.current.scrollBy({
            left: -300,
            behavior: 'smooth',
        });
    };

    const scrollRight = () => {
        listRef.current.scrollBy({
            left: 300,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        const list = listRef.current;
        updateScrollButtons();
        list.addEventListener('scroll', updateScrollButtons);
        return () => list.removeEventListener('scroll', updateScrollButtons);
    }, []);

    return (
        <div className="anime-block">
            <h3>{title}</h3>
            <div className="anime-block-container">
                {canScrollLeft && (
                    <button className="scroll-button left" onClick={scrollLeft}>
                        ◀
                    </button>
                )}
                <div className="anime-list" ref={listRef} style={{ gap: `${gap}px` }}>
                    {items.map((item, index) => (
                        <div
                            key={index}
                            className={`anime-card ${hoverEffect ? 'hover-enabled' : ''}`}
                            onClick={() => onCardClick && onCardClick(item)} // Передаем весь объект `item`
                        >
                            <div className="image-container">
                                <img src={item.image} alt={item.title} style={imageStyle} />
                                {overlay && <div className="overlay"></div>}
                                {hoverEffect && item.description && (
                                    <div className="description">
                                        <p className="description-text">{item.description}</p>
                                    </div>
                                )}

                                {item.rating && <div className="rating-badge">{item.rating}</div>}
                                {item.progress !== undefined && (
                                    <div className="progress-bar">
                                        <div
                                            className="progress-fill"
                                            style={{ width: `${item.progress}%` }}
                                        ></div>
                                    </div>
                                )}
                            </div>
                            <p style={{ ...titleStyle, maxWidth: textWidth }}>{item.title}</p>
                            <span style={{ ...infoStyle, maxWidth: textWidth }}>{item.info}</span>
                        </div>
                    ))}
                </div>
                {canScrollRight && (
                    <button className="scroll-button right" onClick={scrollRight}>
                        ▶
                    </button>
                )}
            </div>
        </div>
    );
};

export default AnimeBlock;
