import React from 'react';
import { useParams } from 'react-router-dom';
import Header from '../components/Header';
import AnimeInfo from '../components/AnimeInfo';
import './Info.css';

const Info = () => {
    const { animeId } = useParams();
    
    return (
        <div>
            <Header />
            <AnimeInfo animeId={animeId} />
        </div>
    );
};

export default Info;
