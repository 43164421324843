import React, { useRef, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './Player.css';

const Player = ({ animeId }) => {
    const seasonsRef = useRef(null);
    const location = useLocation();
    const [isPlayerDropdownOpen, setPlayerDropdownOpen] = useState(false);
    const [isAudioDropdownOpen, setAudioDropdownOpen] = useState(false);
    const [selectedPlayer, setSelectedPlayer] = useState('Kodik');
    const [selectedAudio, setSelectedAudio] = useState(null);
    const [animeData, setAnimeData] = useState(null);
    const [currentSeason, setCurrentSeason] = useState(1);
    const [currentEpisodeLinks, setCurrentEpisodeLinks] = useState([]);
    const [iframeSrc, setIframeSrc] = useState('');
    const [selectedEpisode, setSelectedEpisode] = useState(null);
    const [availableAudios, setAvailableAudios] = useState([]);
    const [animeDataVoice, setAnimeDataVoice] = useState(null);
    const [currentDuration, setCurrentDuration] = useState(0);

    const currentDurationRef = useRef(0);
    const lastUpdateTimeRef = useRef(0);

    const params = new URLSearchParams(location.search);
    const initialEpisode = parseInt(params.get('episode'), 10) || null;
    const players = ['Kodik'];

    useEffect(() => {
        const fetchAnimeData = async () => {
            try {
                const response = await fetch(`https://api.anilis.fun/anime/${animeId}`);
                if (!response.ok) throw new Error(`Ошибка HTTP: ${response.status}`);
                const data = await response.json();
                setAnimeData(data);

                const voiceResponse = await fetch(`https://api.anilis.fun/anime/chechvoice/${encodeURIComponent(data.title)}`);
                if (!voiceResponse.ok) throw new Error(`Ошибка HTTP: ${voiceResponse.status}`);
                const voiceData = await voiceResponse.json();
                setAnimeDataVoice(voiceData);

                const filteredAudios = Object.keys(voiceData.studios || {}).filter((studio) => studio !== 'Субтитры');
                setAvailableAudios(filteredAudios);

                const selectedStudio = Object.entries(voiceData.studios || {}).find(([_, ids]) => ids.includes(animeId));
                setSelectedAudio(selectedStudio ? selectedStudio[0] : filteredAudios[0] || null);

                const seasons = data.seasons || {};
                const firstSeasonKey = Object.keys(seasons)[0];
                if (firstSeasonKey) {
                    const seasonData = seasons[firstSeasonKey];
                    const episodes = Object.entries(seasonData.episodes || {}).map(([key, value]) => ({
                        name: `Серия ${key}`,
                        url: value.link,
                        screenshots: value.screenshots,
                    }));
                    setCurrentSeason(firstSeasonKey);
                    setCurrentEpisodeLinks(episodes);
                    setIframeSrc(episodes[0]?.url || '');
                    setSelectedEpisode(episodes[0]?.name || null);

                    const selectedEp = initialEpisode
                        ? episodes.find((ep) => parseInt(ep.name.replace(/\D/g, '')) === initialEpisode)
                        : episodes[0];
                    if (selectedEp) {
                        setIframeSrc(selectedEp.url || '');
                        setSelectedEpisode(selectedEp.name || null);
                    }
                } else {
                    console.warn('Данные о сезонах отсутствуют.');
                }
            } catch (error) {
                console.error('Ошибка при загрузке данных аниме:', error);
            }
        };

        fetchAnimeData();
    }, [animeId]);

    const selectAudio = async (audio) => {
        console.log(`Выбрана озвучка: ${audio}`);

        try {
            setSelectedAudio(audio);
            setAudioDropdownOpen(false);
            const studioId = animeDataVoice.studios[audio]?.[0];
            console.log(`ID студии: ${studioId}`);

            if (!studioId) throw new Error('ID студии не найден.');

            const response = await fetch(`https://api.anilis.fun/anime/${studioId}`);
            console.log(`Ответ на запрос данных озвучки:`, response);

            if (!response.ok) throw new Error(`Ошибка HTTP: ${response.status}`);

            const data = await response.json();
            console.log(`Данные аниме:`, data);

            const seasons = data.seasons || {};
            const seasonKey = Object.keys(seasons)[0];
            const seasonData = seasons[seasonKey];
            console.log(`Данные сезона:`, seasonData);

            if (!seasonData || !seasonData.episodes) {
                throw new Error('Данные о сезонах или эпизодах отсутствуют.');
            }

            const episodes = Object.entries(seasonData.episodes).map(([key, value]) => ({
                name: `Серия ${key}`,
                url: value.link,
                screenshots: value.screenshots,
            }));
            console.log(`Список эпизодов:`, episodes);

            setCurrentSeason(seasonKey);
            setCurrentEpisodeLinks(episodes);

            const matchingEpisode = episodes.find((episode) => episode.name === selectedEpisode);

            if (matchingEpisode) {
                console.log(`Совпадающий эпизод найден: ${matchingEpisode.name}`);
                setIframeSrc(matchingEpisode.url);
                setSelectedEpisode(matchingEpisode.name);
            } else {
                console.log(`Совпадающий эпизод не найден. Устанавливаем первый эпизод.`);
                setIframeSrc(episodes[0]?.url || '');
                setSelectedEpisode(episodes[0]?.name || null);
            }
        } catch (error) {
            console.error('Ошибка при смене озвучки:', error);
            setCurrentEpisodeLinks([]);
            setIframeSrc('');
            setSelectedEpisode(null);
        }
    };


    let isUpdating = false;

    const sendHistoryUpdate = async (animeId, episodeNumber, watchedTime, duration) => {
        if (isUpdating) return;
        isUpdating = true;

        try {
            const token = localStorage.getItem('authToken');

            const response = await fetch('https://api.anilis.fun/history/add', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({ animeId, episodeNumber, watchedTime, duration }),
            });

            if (!response.ok) {
                throw new Error(`Ошибка при обновлении истории: ${response.status}`);
            }

            console.log('История успешно обновлена');
        } catch (error) {
            console.error('Ошибка при отправке данных истории:', error);
        } finally {
            isUpdating = false;
        }
    };

    useEffect(() => {
        const handlePlayerEvents = (message) => {
            const { key, value } = message.data;

            if (key === 'kodik_player_duration_update') {
                const duration = Math.round(value);
                console.log(`Длительность видео: ${duration} секунд`);
                currentDurationRef.current = duration;
            }

            if (key === 'kodik_player_time_update') {
                const duration = currentDurationRef.current;
                if (!duration || duration <= 0) {
                    console.warn('Длительность видео еще не обновлена. Пропускаем отправку.');
                    return;
                }

                const currentTime = Date.now();
                if (currentTime - lastUpdateTimeRef.current >= 3000) {
                    sendHistoryUpdate(
                        animeId,
                        parseInt(selectedEpisode.replace(/\D/g, ''), 10),
                        value,
                        duration
                    );
                    lastUpdateTimeRef.current = currentTime;
                    console.log('Отправка данных в историю:', {
                        animeId,
                        episodeNumber: parseInt(selectedEpisode.replace(/\D/g, ''), 10),
                        watchedTime: value,
                        duration,
                    });
                } else {
                    console.log('Пока не время отправлять обновление');
                }
            }
        };

        window.addEventListener('message', handlePlayerEvents);

        return () => {
            window.removeEventListener('message', handlePlayerEvents);
        };
    }, [animeId, selectedEpisode]);

    useEffect(() => {
        if (!animeData?.title) return;

        const fetchAnimeDataVoice = async () => {
            try {
                const response = await fetch(`https://api.anilis.fun/anime/chechvoice/${encodeURIComponent(animeData.title)}`);
                if (!response.ok) throw new Error('Ошибка при загрузке данных об озвучке.');
                const voiceData = await response.json();
                setAnimeDataVoice(voiceData);
            } catch (error) {
                console.error('Ошибка при загрузке озвучек:', error);
            }
        };

        fetchAnimeDataVoice();
    }, [animeData?.title]);


    const scrollLeft = () => {
        seasonsRef.current.scrollBy({
            left: -300,
            behavior: 'smooth',
        });
    };

    const scrollRight = () => {
        seasonsRef.current.scrollBy({
            left: 300,
            behavior: 'smooth',
        });
    };

    const togglePlayerDropdown = (e) => {
        e.stopPropagation();
        setPlayerDropdownOpen((prev) => !prev);
        setAudioDropdownOpen(false);
    };

    const toggleAudioDropdown = (e) => {
        e.stopPropagation();
        setAudioDropdownOpen((prev) => !prev);
        setPlayerDropdownOpen(false);
    };

    const selectPlayer = (player) => {
        setSelectedPlayer(player);
        setPlayerDropdownOpen(false);
    };

    const changeEpisode = (url, name) => {
        setIframeSrc(url);
        setSelectedEpisode(name);

        const episodeNumber = parseInt(name.replace(/\D/g, ''), 10) || 0;
        sendHistoryUpdate(animeId, episodeNumber, 0);
    };

    if (!animeData || !animeDataVoice || !currentEpisodeLinks.length) {
        return <div>Загрузка...</div>;
    }


    return (
        <div className="player-page">
            <div className="video-section">
                <iframe
                    className="videoclass"
                    src={iframeSrc + '?hide_selectors=true'}
                    width="610"
                    height="370"
                    frameBorder="0"
                    allowFullScreen
                    allow="autoplay *; fullscreen *"
                ></iframe>
                <h2 className="video-title">{animeData.title}</h2>
            </div>

            <div className="side-panel">
                <div className="seasons-container">
                    <button className="scroll-button-season left" onClick={scrollLeft}>
                        ◀
                    </button>
                    <div className="seasons" ref={seasonsRef}>
                        {Array.from({ length: animeData.season }, (_, i) => (
                            <button
                                key={i + 1}
                                className={`season-button ${currentSeason === i + 1 ? 'active' : ''}`}
                                onClick={() => setCurrentSeason(i + 1)}
                            >
                                {i + 1} сезон
                            </button>
                        ))}
                    </div>
                    <button className="scroll-button-season right" onClick={scrollRight}>
                        ▶
                    </button>
                </div>

                <div className="episodes">
                    <h3>Список серий</h3>
                    <div className="episode-list">
                        {currentEpisodeLinks.length > 0 ? (
                            currentEpisodeLinks.map((episode, index) => (
                                <div
                                    key={index}
                                    className={`episode-item ${selectedEpisode === episode.name ? 'episode-item-active' : ''}`}
                                    onClick={() => changeEpisode(episode.url, episode.name)}
                                >
                                    <div
                                        className="episode-thumbnail"
                                        style={{
                                            backgroundImage: `url('${episode.screenshots?.[0] || ''}')`,
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                        }}
                                    />
                                    <div className="episode-info">
                                        <p>{episode.name}</p>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p>Эпизоды отсутствуют</p>
                        )}
                    </div>
                </div>

                <div className="side-panel-bottom">
                    <div className="dropdown">
                        <div className="option-group">
                            <div className="option-text">
                                <h4>Плеер</h4>
                                <p>Выберите плеер.</p>
                            </div>
                            <button className="option-button active" onClick={togglePlayerDropdown}>
                                {selectedPlayer}
                            </button>
                        </div>
                        {isPlayerDropdownOpen && (
                            <ul className="dropdown-menu player">
                                {players.map((player) => (
                                    <li
                                        key={player}
                                        className={player === selectedPlayer ? 'active' : ''}
                                        onClick={() => selectPlayer(player)}
                                    >
                                        {player}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>

                    <div className="dropdown">
                        <div className="option-group">
                            <div className="option-text">
                                <h4>Озвучка</h4>
                                <p>Выберите озвучку.</p>
                            </div>
                            <button className="option-button active" onClick={toggleAudioDropdown}>
                                {selectedAudio}
                            </button>
                        </div>
                        {isAudioDropdownOpen && (
                            <ul className="dropdown-menu audio">
                                {availableAudios.map((audio) => (
                                    <li
                                        key={audio}
                                        className={audio === selectedAudio ? 'active' : ''}
                                        onClick={() => selectAudio(audio)}
                                    >
                                        {audio}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>

                </div>
            </div>
        </div>
    );
};

export default Player;
