import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./ProfilePage.css";
import AnimeBlock from '../components/AnimeBlock';

const ProfilePage = ({ user, setUser }) => {
    const [library, setLibrary] = useState({
        watchlist: [],
        favorites: [],
        dropped: [],
        watched: [],
    });

    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const handleAnimeClick = (animeId) => {
        navigate(`/info/${animeId}`);
    };

    const fetchAnimeDetails = async (animeId) => {
        try {
            const response = await fetch(`https://api.anilis.fun/anime/${animeId}`);
            if (!response.ok) {
                throw new Error("Ошибка при загрузке данных аниме.");
            }
            const anime = await response.json();
            return {
                id: anime.id,
                title: anime.title,
                poster: anime.material_data.anime_poster_url,
                info: `${anime.material_data.episodes_total} серий`,
                rating: anime.material_data.shikimori_rating,
                description: `${anime.year}, ${anime.material_data.anime_genres?.join(', ')}`,
            };
        } catch (error) {
            console.error("Ошибка при загрузке данных аниме:", error);
            return {
                id: animeId,
                title: "Неизвестно",
                poster: "",
                info: "Данные недоступны",
            };
        }
    };

    useEffect(() => {
        const fetchLibraryWithDetails = async () => {
            try {
                const token = localStorage.getItem("authToken");
                const response = await fetch(`https://api.anilis.fun/library`, {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (!response.ok) {
                    throw new Error("Ошибка при загрузке библиотеки.");
                }

                const data = await response.json();

                const libraryWithDetails = {};
                for (const listType in data) {
                    libraryWithDetails[listType] = await Promise.all(
                        data[listType].map((animeId) => fetchAnimeDetails(animeId))
                    );
                }

                setLibrary(libraryWithDetails);
            } catch (error) {
                console.error("Ошибка при загрузке библиотеки:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchLibraryWithDetails();
    }, []);

    const handleLogout = () => {
        localStorage.removeItem("authToken");
        setUser(null);
        navigate("/login");
    };

    if (!user) {
        return (
            <div className="profile-page">
                <h2>Вы не авторизованы</h2>
                <button onClick={() => navigate("/login")}>Войти</button>
            </div>
        );
    }

    if (loading) {
        return (
            <div className="profile-page">
                <h2>Загрузка...</h2>
            </div>
        );
    }

    const sectionTitles = {
        watchlist: "Буду смотреть",
        favorites: "Избранное",
        dropped: "Заброшено",
        watched: "Просмотрено",
    };

    const displayOrder = ["watchlist", "favorites", "dropped", "watched"];

    return (
        <div className="profile-page">
            <h1>Профиль</h1>
            <div className="library">
                {displayOrder.map((listType) => (
                    <div key={listType} className="library-section">
                        <AnimeBlock
                            title={sectionTitles[listType]}
                            items={library[listType].map((anime) => ({
                                image: anime.poster,
                                title: anime.title,
                                info: anime.info,
                                rating: anime.rating,
                                description: anime.description,
                                id: anime.id,
                            }))}
                            imageStyle={{ width: '128px', height: '182px' }}
                            textWidth="128px"
                            gap={27}
                            hoverEffect={true}
                            onCardClick={(item) => handleAnimeClick(item.id)}
                            titleStyle={{ color: 'white' }}
                            infoStyle={{ color: 'gray' }}
                        />
                    </div>
                ))}
            </div>

            <button className="logout-button" onClick={handleLogout}>
                Выйти из аккаунта
            </button>
        </div>
    );
};

export default ProfilePage;
