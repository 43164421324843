import React from 'react';
import { useParams } from 'react-router-dom';
import Header from '../components/Header';
import Player from '../components/Player';
import './Video.css';

const Video = () => {
    const { animeId } = useParams();

    return (
        <div>
            <Header />
            <Player animeId={animeId} />
        </div>
    );
};

export default Video;
