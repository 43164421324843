import React, { useEffect, useState } from "react";
import "./Header.css";
import { ReactComponent as Logo } from "./logo.svg";
import { useNavigate, Link } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [username, setUsername] = useState(localStorage.getItem("username"));

  useEffect(() => {
    const authToken = localStorage.getItem("authToken");

    if (authToken) {
      if (!username) {
        fetch("https://api.anilis.fun/auth/profile", {
          headers: { Authorization: `Bearer ${authToken}` },
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("Ошибка при получении данных пользователя");
            }
            return response.json();
          })
          .then((data) => {
            setUser(data);
            setUsername(data.username);
            localStorage.setItem("username", data.username);
          })
          .catch((err) => {
            console.error("Ошибка авторизации:", err);
            localStorage.removeItem("authToken");
          });
      } else {
        setUser({ username });
      }
    }
  }, [username]);

  const handleRandomClick = async () => {
    try {
      const response = await fetch("https://api.anilis.fun/anime/random");
      if (!response.ok) {
        throw new Error(`Ошибка HTTP: ${response.status}`);
      }
      const randomAnime = await response.json();
      navigate(`/info/${randomAnime.id}`);
    } catch (error) {
      console.error("Ошибка при получении случайного аниме:", error);
    }
  };

  const handleLoginClick = () => {
    if (user) {
      navigate("/profile");
    } else {
      navigate("/login");
    }
  };

  return (
    <header className="header">
      <div className="logo">
        <Link to="/">
          <Logo />
        </Link>
      </div>
      <nav className="nav">
        <a href="/">Главная</a>
        <a href="/history">История</a>
        <button className="random-button" onClick={handleRandomClick}>
          Случайное
        </button>
        <a href="/search">Поиск</a>
      </nav>
      <button
        className={`login-button ${username ? "user-button" : ""}`}
        onClick={handleLoginClick}
      >
        {username || "Войти"}
      </button>
    </header>
  );
};

export default Header;
