import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { useNavigate } from "react-router-dom";
import "./AnimeInfo.css";

const AnimeInfo = ({ animeId }) => {
    const [animeData, setAnimeData] = useState(null);
    const [isDataLoading, setIsDataLoading] = useState(true);
    const [isPosterLoading, setIsPosterLoading] = useState(true);
    const [isScreenshotsLoading, setIsScreenshotsLoading] = useState(true);
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [voiceLoading, setVoiceLoading] = useState(false);
    const [animeDataVoice, setAnimeDataVoice] = useState(null);
    const [library, setLibrary] = useState({
        watchlist: [],
        favorites: [],
        watched: [],
        dropped: [],
    });
    const navigate = useNavigate();

    useEffect(() => {
        console.log(`[FETCH] Начало загрузки данных для animeId: ${animeId}`);
        setIsDataLoading(true);
        setIsScreenshotsLoading(true);
        setIsPosterLoading(true);

        const fetchAnimeData = async () => {
            try {
                const response = await fetch(`https://api.anilis.fun/anime/${animeId}`);
                if (!response.ok) throw new Error("Ошибка при загрузке данных.");
                const data = await response.json();
                console.log("[FETCH] Данные загружены:", data);
                setAnimeData(data);
                setIsDataLoading(false);

                const posterImg = new Image();
                posterImg.src = data.material_data.anime_poster_url;
                if (posterImg.complete) {
                    setIsPosterLoading(false);
                } else {
                    posterImg.onload = () => setIsPosterLoading(false);
                    posterImg.onerror = () => setIsPosterLoading(false);
                }

                if (data?.screenshots?.length) {
                    console.log("[IMAGES] Начало загрузки изображений:", data.screenshots);
                    await Promise.all(
                        data.screenshots.map(
                            (src) =>
                                new Promise((resolve, reject) => {
                                    const img = new Image();
                                    img.src = src;
                                    if (img.complete) {
                                        resolve();
                                    } else {
                                        img.onload = resolve;
                                        img.onerror = resolve;
                                    }
                                })
                        )
                    );
                    console.log("[IMAGES] Все изображения загружены.");
                }

                setIsScreenshotsLoading(false);
            } catch (error) {
                console.error("[FETCH] Ошибка загрузки данных:", error);
                setIsDataLoading(false);
                setIsPosterLoading(false);
                setIsScreenshotsLoading(false);
            }
        };

        fetchAnimeData();
    }, [animeId]);
    useEffect(() => {
        if (!animeData?.title) return;

        const fetchAnimeDataVoice = async () => {
            setVoiceLoading(true);
            try {
                const response = await fetch(`https://api.anilis.fun/anime/chechvoice/${encodeURIComponent(animeData.title)}`);
                if (!response.ok) throw new Error("Ошибка при загрузке данных об озвучке.");
                const dataVoice = await response.json();
                setAnimeDataVoice(dataVoice);
            } catch (error) {
                console.error("Ошибка при загрузке озвучки:", error);
            } finally {
                setVoiceLoading(false);
            }
        };

        fetchAnimeDataVoice();
    }, [animeData?.title]);
    const handlePlayClick = () => {
        console.log("[NAVIGATE] Переход к плееру.");
        navigate(`/video/${animeId}`);
    };

    const toggleDropdown = () => {
        setDropdownOpen((prevState) => !prevState);
    };

    const fetchLibrary = async () => {
        try {
            const token = localStorage.getItem("authToken");
            const response = await fetch(`https://api.anilis.fun/library`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error("Ошибка при загрузке библиотеки.");
            }

            const data = await response.json();
            setLibrary(data);
        } catch (error) {
            console.error("Ошибка при загрузке библиотеки:", error);
        }
    };

    useEffect(() => {
        fetchLibrary();
    }, []);

    const handleLibraryToggle = async (listType) => {
        const isInList = library[listType]?.includes(animeId);

        try {
            const token = localStorage.getItem("authToken");
            const response = await fetch(
                `https://api.anilis.fun/library/${isInList ? "remove" : "add"}`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({ animeId, listType }),
                }
            );

            if (!response.ok) {
                const errorData = await response.json();
                console.error("Ошибка запроса:", errorData);
                throw new Error(errorData.message || "Ошибка обновления библиотеки.");
            }

            await fetchLibrary();
        } catch (error) {
            console.error("Ошибка обновления библиотеки:", error);
        }
    };



    useEffect(() => {
        if (animeData?.screenshots?.length) {
            animeData.screenshots.forEach((src) => {
                const img = new Image();
                img.src = src;
            });
        }
    }, [animeData]);

    const openLightbox = (index) => {
        setCurrentImageIndex(null);
        setTimeout(() => {
            setCurrentImageIndex(index);
            setLightboxOpen(true);
        }, 10);
    };



    return (
        <div className="anime-info-page">
            <div className="anime-header">
                {isPosterLoading ? (
                    <Skeleton
                        width={250}
                        height={353.33}
                        className="poster-skeleton"
                        baseColor="#222"
                        highlightColor="#333"
                    />
                ) : (
                    <img
                        src={animeData.material_data.anime_poster_url}
                        alt={animeData.title}
                        className="anime-poster"
                    />
                )}
                <div className="anime-details">
                    <h1 className="main-title-anime-info">
                        {isDataLoading ? <Skeleton width={300} /> : animeData.title}
                    </h1>
                    <p className="other_title">
                        {isDataLoading ? <Skeleton width={200} /> : animeData.other_title}
                    </p>
                    <ul className="details-list">
                        <li>
                            <strong>Рейтинг:</strong>{" "}
                            {isDataLoading ? <Skeleton width={50} /> : animeData.material_data.shikimori_rating}
                        </li>
                        <li>
                            <strong>Сезон:</strong> {isDataLoading ? <Skeleton width={30} /> : animeData.last_season}
                        </li>
                        <li>
                            <strong>Эпизоды:</strong>{" "}
                            {isDataLoading ? <Skeleton width={30} /> : animeData.material_data.episodes_total}
                        </li>
                        <li>
                            <strong>Возрастные ограничения:</strong>{" "}
                            {isDataLoading ? <Skeleton width={40} /> : animeData.material_data.minimal_age}
                        </li>
                        <li>
                            <strong>Год:</strong> {isDataLoading ? <Skeleton width={50} /> : animeData.year}
                        </li>
                        <li>
                            <strong>Жанры:</strong>{" "}
                            {isDataLoading ? <Skeleton width={200} /> : animeData.material_data.anime_genres?.join(", ")}
                        </li>
                        {voiceLoading ? (
                            <li><Skeleton width={150} /></li>
                        ) : animeDataVoice?.studios ? (
                            <li>
                                <strong>Озвучка:</strong>{" "}
                                {Object.keys(animeDataVoice.studios)
                                    .filter((studio) => studio !== "Субтитры")
                                    .join(", ")}
                            </li>
                        ) : (
                            <li>Озвучка отсутствует</li>
                        )}
                    </ul>
                </div>
            </div>

            <div className="anime-buttons">
                <button className="icon-button"
                    onClick={handlePlayClick}
                    disabled={isDataLoading}>
                    <span>{isDataLoading ? <Skeleton width={50} /> : "Начать просмотр"}</span>
                </button>
                <button className="icon-button"
                    onClick={toggleDropdown}
                    disabled={isDataLoading}>
                    <span>{isDataLoading ? <Skeleton width={50} /> : "Добавить в список"}</span>
                </button>
                {dropdownOpen && (
                    <div className="dropdown-menu-info">
                        {["watchlist", "favorites", "watched", "dropped"].map((listType) => {
                            const translatedTypes = {
                                watchlist: "буду смотреть",
                                favorites: "избранное",
                                watched: "просмотрено",
                                dropped: "брошенное",
                            };

                            return (
                                <div key={listType} className="dropdown-item">
                                    <button
                                        className={`dropdown-button ${library[listType]?.includes(animeId) ? "active" : ""
                                            }`}
                                        onClick={() => handleLibraryToggle(listType)}
                                    >
                                        {library[listType]?.includes(animeId)
                                            ? `Удалить из ${translatedTypes[listType]}`
                                            : `Добавить в ${translatedTypes[listType]}`}
                                    </button>
                                </div>
                            );
                        })}
                    </div>
                )}

            </div>

            <div className="anime-description">
                <h3>Описание</h3>
                {isDataLoading ? <Skeleton count={5} /> : <p>{animeData.material_data.description || ""}</p>}
            </div>

            <div className="anime-screenshots">
                <h3>Кадры</h3>
                <div className="screenshots-grid">
                    {isScreenshotsLoading
                        ? [...Array(5)].map((_, index) => (
                            <Skeleton key={index} width={227.59} height={128.02} />
                        ))
                        : animeData.screenshots?.map((screenshot, index) => (
                            <img
                                key={index}
                                src={screenshot}
                                alt={`Кадр ${index + 1}`}
                                className="screenshot"
                                onClick={() => openLightbox(index)}
                            />
                        ))}
                </div>
            </div>

            {lightboxOpen && (
                <Lightbox
                    mainSrc={animeData.screenshots[currentImageIndex]}
                    nextSrc={animeData.screenshots[(currentImageIndex + 1) % animeData.screenshots.length]}
                    prevSrc={animeData.screenshots[(currentImageIndex + animeData.screenshots.length - 1) % animeData.screenshots.length]}
                    enableZoom={false}
                    onAfterOpen={() => console.log("Лайтбокс открыт")}
                    onCloseRequest={() => setLightboxOpen(false)}
                    onMovePrevRequest={() =>
                        setCurrentImageIndex(
                            (currentImageIndex + animeData.screenshots.length - 1) % animeData.screenshots.length
                        )
                    }
                    onMoveNextRequest={() =>
                        setCurrentImageIndex((currentImageIndex + 1) % animeData.screenshots.length)
                    }
                />

            )}

        </div>
    );
};

export default AnimeInfo;
