import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Slider from '../components/Slider';
import AnimeBlock from '../components/AnimeBlock';
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import './Home.css';

const Home = () => {
    const [highRated, setHighRated] = useState([]);
    const [randomHome, setRandomAnime] = useState([]);
    const [highRatedFull, setHighRatedFull] = useState([]);
    const [continueWatching, setContinueWatching] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [highRatedFullResponse, highRatedResponse, randomHomeResponse, historyResponse] = await Promise.all([
                    fetch('https://api.anilis.fun/anime/top'),
                    fetch('https://api.anilis.fun/anime/top?year=2024'),
                    fetch('https://api.anilis.fun/anime/top?year=2023'),
                    fetch('https://api.anilis.fun/history', {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
                        },
                    }),
                ]);

                if (!highRatedFullResponse.ok || !highRatedResponse.ok || !randomHomeResponse.ok || !historyResponse.ok) {
                    throw new Error('Ошибка при загрузке данных.');
                }

                const [highRatedFullData, highRatedData, randomHomeData, historyData] = await Promise.all([
                    highRatedFullResponse.json(),
                    highRatedResponse.json(),
                    randomHomeResponse.json(),
                    historyResponse.json(),
                ]);

                const continueWatchingData = await Promise.all(
                    historyData.map(async (item) => {
                        console.log("Обработка записи:", item);

                        const animeResponse = await fetch(`https://api.anilis.fun/anime/${item.animeId}`);
                        if (!animeResponse.ok) throw new Error('Ошибка при загрузке данных аниме.');
                        const animeData = await animeResponse.json();

                        console.log("Данные аниме:", animeData);

                        const season = animeData.seasons?.[1] || animeData.seasons?.[0];
                        const episodeScreenshots = season?.episodes?.[item.lastWatchedEpisode]?.screenshots;

                        return {
                            image: episodeScreenshots?.[0] || animeData.material_data.poster_url,
                            title: item.title,
                            info: `Сезон 1, Серия ${item.lastWatchedEpisode}`,
                            progress: item.progress,
                            animeId: item.animeId,
                            episodeNumber: item.lastWatchedEpisode,
                        };
                    })
                );

                setHighRatedFull(highRatedFullData);
                setHighRated(highRatedData);
                setRandomAnime(randomHomeData);
                setContinueWatching(continueWatchingData);

                console.log("Список для 'Продолжить просмотр':", continueWatchingData);
            } catch (error) {
                console.error('Ошибка загрузки данных:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleAnimeClick = (animeId) => {
        navigate(`/info/${animeId}`);
    };

    const handleAnimeClickCont = (animeId, episodeNumber) => {
        console.log(animeId);
        if (!animeId || !episodeNumber) {
            console.error("animeId или episodeNumber отсутствует:", { animeId, episodeNumber });
            return;
        }
        navigate(`/video/${animeId}?episode=${episodeNumber}`);
    };

    return (
        <div className="scroll-home">
            <Header />
            <Slider />
            <div className="home-container">
                <AnimeBlock
                    title="Продолжить просмотр"
                    items={continueWatching}
                    imageStyle={{ width: '229px', height: '129px' }}
                    textWidth="229px"
                    gap={17}
                    overlay={true}
                    onCardClick={(item) => {
                        if (!item || !item.animeId || !item.episodeNumber) {
                            console.error("Некорректные данные для 'Продолжить просмотр':", item);
                            return;
                        }
                        handleAnimeClickCont(item.animeId, item.episodeNumber);
                    }}
                />


                <AnimeBlock
                    title="С высоким рейтингом"
                    items={highRatedFull.map((anime) => ({
                        image: anime.material_data.anime_poster_url,
                        title: anime.title,
                        info: `${anime.material_data.episodes_total} серий`,
                        rating: anime.material_data.shikimori_rating,
                        description: `${anime.year}, ${anime.material_data.anime_genres?.join(', ')}`,
                        id: anime.id,
                    }))}
                    imageStyle={{ width: '128px', height: '182px' }}
                    textWidth="128px"
                    gap={27}
                    hoverEffect={true}
                    onCardClick={(item) => handleAnimeClick(item.id)}
                />
                <AnimeBlock
                    title="Топ аниме 2024"
                    items={highRated.map((anime) => ({
                        image: anime.material_data.anime_poster_url,
                        title: anime.title,
                        info: `${anime.material_data.episodes_total} серий`,
                        rating: anime.material_data.shikimori_rating,
                        description: `${anime.year}, ${anime.material_data.anime_genres?.join(', ')}`,
                        id: anime.id,
                    }))}
                    imageStyle={{ width: '128px', height: '182px' }}
                    textWidth="128px"
                    gap={27}
                    hoverEffect={true}
                    onCardClick={(item) => handleAnimeClick(item.id)}
                />
                <AnimeBlock
                    title="Топ аниме 2023"
                    items={randomHome.map((anime) => ({
                        image: anime.material_data.anime_poster_url,
                        title: anime.material_data.title,
                        info: `${anime.material_data.episodes_total} серий`,
                        rating: anime.material_data.shikimori_rating,
                        description: `${anime.year}, ${anime.material_data.anime_genres?.join(', ')}`,
                        id: anime.id,
                    }))}
                    imageStyle={{ width: '128px', height: '182px' }}
                    textWidth="128px"
                    gap={27}
                    hoverEffect={true}
                    onCardClick={(item) => handleAnimeClick(item.id)}
                />
            </div>
        </div>
    );
};

export default Home;
