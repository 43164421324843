import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Info from './pages/Info';
import Home from './pages/Home';
import Video from './pages/Video';
import Search from './pages/Search';
import Register from './pages/Register';
import Login from './pages/Login';
import Profile from './pages/Profile';


function App() {
  const [user, setUser] = React.useState(null);

  React.useEffect(() => {
    const authToken = localStorage.getItem("authToken");
    if (authToken) {
      fetch("https://api.anilis.fun/auth/profile", {
        headers: { Authorization: `Bearer ${authToken}` },
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error("Failed to fetch user data");
          }
          return res.json();
        })
        .then((data) => setUser(data))
        .catch((err) => {
          console.error(err);
          setUser(null);
        });
    }
  }, []);
  
  return (
    
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/video/:animeId" element={<Video />} />
        <Route path="/info/:animeId" element={<Info />} />
        <Route path="/search" element={<Search />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/profile" element={<Profile user={user} setUser={setUser} />} />
      </Routes>
    </Router>
  );
}

export default App;
