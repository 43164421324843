import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './SearchPage.css';

const SearchPage = () => {
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleSearch = async () => {
        if (query.trim() === '') {
            alert('Введите запрос для поиска!');
            return;
        }

        setLoading(true);
        setError(null);
        setResults([]);

        try {
            const response = await fetch(`https://api.anilis.fun/anime/search?query=${encodeURIComponent(query)}`);
            if (!response.ok) {
                throw new Error('Ошибка при выполнении поиска.');
            }
            const data = await response.json();
            setResults(data);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };

    const handleAnimeClick = (id) => {
        navigate(`/info/${id}`);
    };

    return (
        <div className="search-page">
            <h1>Поиск аниме</h1>
            <div className="search-bar">
                <input
                    type="text"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    onKeyPress={handleKeyPress}
                    placeholder="Введите название аниме..."
                />
                <button onClick={handleSearch}>Поиск</button>
            </div>

            {loading && <div className="loading">Загрузка...</div>}
            {error && <div className="error">{error}</div>}
            {results.length > 0 && (
                <div className="results">
                    <h2>Результаты поиска</h2>
                    <ul>
                        {results.map((anime) => (
                            <li
                                key={anime.id}
                                className="anime-card-search"
                                onClick={() => handleAnimeClick(anime.id)}
                                style={{ cursor: 'pointer' }}
                            >
                                <img src={anime.material_data.anime_poster_url || '/placeholder.png'} alt={anime.title} />
                                <div className="anime-info">
                                    <h3>{anime.title}</h3>
                                    <p>{anime.material_data.description || 'Описание отсутствует.'}</p>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default SearchPage;
